// 给链接拼接参数
/*
 * url：链接地址。http://localhost:8081?sdfa=dsfa...
 * params: 需要拼接的参数。amp_device_id=pZbt5EW1_JnQKHJZ3TW5tR&utm_source=fadsfasdf
 * */
export default function resetUrl(url, params) {
  let urlString = url;
  const hasParams = url.indexOf("?") > -1;
  const paramsNotNull = params?.length > 0;
  if (paramsNotNull) {
    urlString += hasParams ? `&${params}` : `?${params}`;
  }
  return urlString;
}
